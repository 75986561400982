<template>
	<div class="row mt-4">
		<LoadingSpinner v-if="loading" />

		<template v-else>
			<div v-if="parent" class="col-12 mt-2">
				<b-button @click="backMap"><font-awesome-icon :icon="['far', 'angle-left']" /> {{ $t('global.retour') }}</b-button>
			</div>
			<div ref="img_wrapper" class="col-12 text-center">
				<img ref="parent_img" :src="img_url" @load="onLoadImg">
				<div class="border canva_container">
					<canvas role="button" ref="canvas" @click="clickCanvas">
					</canvas>
				</div>
			</div>
			<div class="col-12 mt-2" v-if="uncounted_horses.length > 0">
				<b-alert show variant="warning">
					<font-awesome-icon :icon="['far', 'info-circle']" style="color: #8F5922;"/>
					<span v-if="uncounted_horses.length == 1">{{ ' ' + $t('horse.loc.info_singulier') }}:</span>
					<span v-else>{{ ' ' + $t('horse.loc.info_pluriel') }}:</span>
					<span v-for="horse, index in uncounted_horses" :key="horse.horse_id">
						<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.horse_id }}">
							{{ horse.horse_nom }}
						</router-link>
						<template v-if="index < uncounted_horses.length - 1">,</template>
					</span>
				</b-alert>
			</div>
			<div class="col-12 mt-2">
				<h4 v-if="actes_late.length > 0" class="d-flex align-items-center" style="color:#DC1258"><span class="acte_color" style="border-color:#DC1258; background-color: rgba(220, 18, 88, 0.25)"></span> {{ $t('horse.loc.actes_late') }}</h4>
				<div v-for="acte in actes_late" :key="acte.acte_type.id" role="button">
					<div class="acte_elt" @click="openListeActe(acte.acte_type)">
						{{ acte.nb }} {{ acte.acte_type.name }} <font-awesome-icon :icon="['far', 'angle-right']" />
					</div>
				</div>

				<h4 v-if="actes_todo.length > 0" class="d-flex align-items-center mt-4" style="color:#f1c40f"><span class="acte_color" style="border-color:#f1c40f; background-color: rgba(241, 196, 15, 0.25)"></span> {{ $t('horse.loc.actes_todo') }}</h4>
				<div v-for="acte in actes_todo" :key="acte.acte_type.id" role="button">
					<div class="acte_elt" @click="openListeActe(acte.acte_type)">
						{{ acte.nb }} {{ acte.acte_type.name }} <font-awesome-icon :icon="['far', 'angle-right']" />
					</div>
				</div>
			</div>
		</template>

        <ModalReplan 
            ref="modal_actes_replan"
            @ok="applyReplanActe"
        />

        <ModalDemandeAnalyse
            ref="modal_demande_analyse"
        />

        <ModalCourrierActes ref="modal_courrier_actes"/>
	</div>
</template>
<script type="text/javascript">
	import Actes from '@/mixins/Actes'
	import Lieux from '@/mixins/Lieux'
	import IntraLocation from '@/mixins/IntraLocation'
	import Navigation from '@/mixins/Navigation'
    import Shutter from "@/mixins/Shutter.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'
    import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'MapLocalisation',
		mixins: [Actes, Lieux, IntraLocation, Navigation, Shutter, ShutterActs],
		props: ['residence', 'intralocation_label', 'contacts', 'date'],
		data () {
			return {
				intra_locations: [],
				img_url: '',
				image_loaded: true,
				loading: false,
				intra_location_labels: [],
				parent: null,
				actes_types: [],
				actes_late: [],
				actes_todo: [],
				uncounted_horses: [],
                events_tab: {
                    'TableAction::acteAjout': this.handleActesOperations,
                    'TableAction::DeleteActes': this.deleteActePostConfirmation,
                    'TableAction::ReplanActe': this.replanActesModal,
                    'TableAction::goToAnalyse': this.openModalAnalyse,
                    'TableAction::goToDupliqueActe': this.dupliqueActe,
                    'TableAction::goToPrintActs': this.openModalCourrierActes
                },
                last_operation: '',
                last_row_id: 0,
                ratio: 1
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.residence && this.residence.lieu_id) {
					this.loadFromResidence(this.residence)
				}

				let actes_types = await this.getActesTypes()
				actes_types = actes_types.filter(acte_type => acte_type.actestype_label !== "---")

				this.actes_types = actes_types.map(type => ({
					id: type.actestype_id,
					name: type.actestype_label,
					group: type.actestype_codegroupe
				}))
			},

			async loadFromResidence() {
				this.loading = true
				const contacts_ids = this.contacts.map(contact => contact.contact_id)

				let infos = await this.getIntraLocationsWithActeByResidence(this.residence.lieu_id, this.date.toDateInputValue(), contacts_ids)
				this.intra_locations = infos.intralocations.filter(intra => intra.intralocation_shape)
				this.uncounted_horses = infos.uncounted_horses
				this.img_url = this.residence.lieu_img_url
				this.parent = null

				this.$emit('update:intralocation_label', '')
				this.loading = false

				this.formatActeTypeStatus()
			},

			async loadFromIntraLocation(loc) {
				this.loading = true
				const contacts_ids = this.contacts.map(contact => contact.contact_id)

				let intra_locations = await this.getIntraLocationsWithActeByIntraLocation(loc.intralocation_id, this.date.toDateInputValue(), contacts_ids)
				this.intra_locations = intra_locations.filter(intra => intra.intralocation_shape)
				this.img_url = loc.intralocation_img_url
				this.parent = {
					type: loc.intralocation_parent_type,
					intralocation_id: loc.intralocation_parent,
					intralocation_img_url: loc.intralocation_img_url
				}

				this.intra_location_labels.push(loc.intralocation_label)
				this.$emit('update:intralocation_label', ' / '+this.intra_location_labels.join(' / '))
				this.loading = false

				this.formatActeTypeStatus()
			},

			clickCanvas(event) {
    			const ctx = this.$refs.canvas.getContext("2d")
				const rect = this.$refs.canvas.getBoundingClientRect()

				this.intra_locations.forEach(loc => {
					const fill = new Path2D()
    				fill.moveTo(this.ratio*loc.intralocation_shape.fill[0][0], this.ratio*loc.intralocation_shape.fill[0][1])
	    			loc.intralocation_shape.fill.forEach(path => {
						fill.lineTo(this.ratio*path[0], this.ratio*path[1])
	    			})
					fill.lineTo(this.ratio*loc.intralocation_shape.fill[0][0], this.ratio*loc.intralocation_shape.fill[0][1])

					const x = event.clientX - rect.left
    				const y = event.clientY - rect.top

					let on_fill = ctx.isPointInPath(fill, x, y)

					if(on_fill) {
						if(loc.has_children) {
							this.loadFromIntraLocation(loc)
						}
						else if(loc.nb_horses == 1) {
							let date = _cloneDeep(this.date)
							this.setupHorseActes(loc.horse_info.horse_id, 0, true, new Date(date.setMonth(date.getMonth() - 3)), loc.horse_info.horse_nom)
						}
						return true
					}
				})
			},

			onLoadImg() {
				this.image_loaded = true
				this.drawShape()
			},

			loadImg() {
			    const ctx = this.$refs.canvas.getContext("2d");
			    const width_img = this.$refs.parent_img.clientWidth;
			    const height_img = this.$refs.parent_img.clientHeight;

			    if (window.innerWidth < 1200) {
			        this.$refs.canvas.width = 1200;
			        this.$refs.canvas.height = (height_img / width_img) * 1200;
			    }
			    else {
			        this.$refs.canvas.width = this.$refs.img_wrapper.clientWidth - 40;
			        this.$refs.canvas.height = (height_img / width_img) * this.$refs.canvas.width;
			    }

			    this.$refs.parent_img.classList.add('d-none');
			    ctx.drawImage(this.$refs.parent_img, 10, 10, this.$refs.canvas.width, this.$refs.canvas.height);

			    this.ratio = this.$refs.canvas.width / width_img;
			},

			drawShape() {
				if(!this.$refs.canvas) return false
    			const ctx = this.$refs.canvas.getContext("2d")
    			ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

    			this.loadImg()

				if(!this.image_loaded) return false

    			this.intra_locations.forEach(loc => {
    				ctx.beginPath();
    				ctx.moveTo(this.ratio*loc.intralocation_shape.fill[0][0], this.ratio*loc.intralocation_shape.fill[0][1])
	    			loc.intralocation_shape.fill.forEach(path => {
						ctx.lineTo(this.ratio*path[0], this.ratio*path[1])
	    			})
					ctx.lineTo(this.ratio*loc.intralocation_shape.fill[0][0], this.ratio*loc.intralocation_shape.fill[0][1])

					ctx.fillStyle = "#ffffff"
	    			// ctx.fillStyle = loc.intralocation_shape.color
	    			// ctx.strokeStyle = loc.intralocation_shape.color
	    			// ctx.globalAlpha = 0.5

					if(Object.keys(loc.actes.late).length > 0) {
						ctx.strokeStyle = "#DC1258";
						ctx.fillStyle = "rgba(220, 18, 88, 0.25)";
					}
					else if(Object.keys(loc.actes.todo).length > 0) {
						ctx.strokeStyle = "#f1c40f"
						ctx.fillStyle = "rgba(241, 196, 15, 0.25)";
					}
					else {
						ctx.strokeStyle = "#2ecc71"
						ctx.fillStyle = "rgba(46, 204, 113, 0.25)";
					}
					ctx.lineWidth = 3

					ctx.fill();
					ctx.stroke();
    			})

    			this.intra_locations.forEach(loc => {
    				let center = this.getCenter(loc.intralocation_shape.fill)

    				ctx.textAlign = "center"
					ctx.textBaseline = "middle"
					ctx.fillStyle = loc.intralocation_shape.color ?? "#ffffff"
					ctx.font = "16px lato, sans-serif"
					ctx.lineWidth=5;

    				ctx.fillText(loc.intralocation_label, this.ratio*center[0], this.ratio*center[1] - 10)
					if(loc.nb_horses == 1) {
						let horse_nom = loc.horse_info.horse_alias && loc.horse_info.horse_alias != "" ? loc.horse_info.horse_alias : loc.horse_info.horse_nom
						horse_nom = this.truncate(horse_nom, 7) // On limite le nom des chevaux à 7 caractères
    					ctx.fillText(horse_nom, this.ratio*center[0], this.ratio*center[1] + 15)
					}
					else if(loc.nb_horses > 0) {
						const label = loc.nb_horses + this.getTrad('global.chevaux_lowercase')
    					ctx.fillText(label, this.ratio*center[0], this.ratio*center[1] + 15)
					}
    			})
			},

			getCenter(coors) {
				let x = coors.reduce((acc, coor) => {
				 	return acc + coor[0]
				}, 0);
				let y = coors.reduce((acc, coor) => {
				 	return acc + coor[1]
				}, 0);

				return [x/coors.length, y/coors.length]
			},

			backMap() {
				this.intra_location_labels.pop()
				if(this.parent.type == 'intra_location') {
					this.loadFromIntraLocation(this.parent)
				}
				else {
					this.loadFromResidence()
				}
			},

			formatActeTypeStatus() {
				let actes_type_late = {}
				let actes_type_todo = {}
				let hasOwn = Object.prototype.hasOwnProperty

				this.intra_locations.forEach(loc => {
					for(let type in loc.actes.late) {
						if(!hasOwn.call(actes_type_late, type)) {
							actes_type_late[type] = {
								nb: 0,
								color: '#DC1258',
								acte_type: this.actes_types.find(acte => acte.id == type)
							}
						}
						actes_type_late[type].nb += loc.actes.late[type]
					}

					for(let type in loc.actes.todo) {
						if(!hasOwn.call(actes_type_todo, type)) {
							actes_type_todo[type] = {
								nb: 0,
								color: '#f1c40f',
								acte_type: this.actes_types.find(acte => acte.id == type)
							}
						}
						actes_type_todo[type].nb += loc.actes.todo[type]
					}
				})

				this.actes_late = Object.values(actes_type_late)
				this.actes_todo = Object.values(actes_type_todo)
			},

			openListeActe(acte_type) {
				let date = _cloneDeep(this.date)
				this.setupActList([acte_type.id], new Date(date.setMonth(date.getMonth() - 3)))
			},

			truncate(str, n){
				n += 2 // Pour avoir la longueur réelle
				return (str.length > n) ? str.slice(0, n-1) + '...' : str;
			},

            async handleActesOperations(params) {
                const operation = params.state || 'add'
                const row_id = params.pk_id || params.acte_id || params.horse_id

                if(operation == 'add') {
                    this.setupActAdd('')
                }
                else if(operation == 'modification') {
                    this.setupActEdit(row_id, params.lot)
                }
                else if(operation == 'validation') {
                    this.setupActValidation(row_id)
                }
            },

            deleteActePostConfirmation(actes) {
                const act_ids = actes.map(act => act.actes_id)

                // Si on a la prévisualisation sur un des actes supprimés
                if(!this.lastOperationIsAboutHorse && act_ids.indexOf(this.last_row_id) > -1) {
                    this.closeAllShutters()
                }

                this.applyDeleteActes(actes).then(() => {
                    this.shutterPanel().reload('act-list')
                    // this.shutterPanel().reload('horse-gyneco')
                })
            },

            replanActesModal(actes) {
				this.interacted_actes = actes
                const date = actes.length === 1 ? Date.parseTz(actes[0].actes_date) : new Date()
                this.$refs.modal_actes_replan.openModal(date, actes)
			},

            openModalAnalyse(actes) {
                this.$refs.modal_demande_analyse.openModal(actes)
            },

            async dupliqueActe(acte_id) {
               const acte = await this.getActeFromId(acte_id, 'withContactAndArticles')
               this.setupActDuplication(acte)
            },

            openModalCourrierActes(acte_ids) {
                this.$refs.modal_courrier_actes.openModal(acte_ids)
            },

            closeAllShutters() {
                this.shutterPanel().goTo('act-list')
                this.shutterPanel().close('act-add-form')
                this.shutterPanel().close('act-edit')
                this.shutterPanel().close('act-valid')
            },

            onShuttersSubmit() {
                this.shutterPanel().reload('act-list')
                this.closeAllShutters()
            },
		},

		computed: {
            lastOperationIsAboutHorse() {
                return this.last_operation == 'horse_gyneco'
            }
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ModalCourrierActes: () => import('@/components/Modals/ModalCourrierActes'),
            ModalReplan: () => import('@/components/Modals/ModalReplan'),
            ModalDemandeAnalyse: () => import('@/components/Modals/ModalDemandeAnalyse'),
		}
	}

</script>